import React from "react";
import styled from "styled-components";
import IconButton from "../atoms/IconButton";
import Row from "../atoms/Row";
import { Colors, Typography } from "../atoms/Theme";
import { FaTimes } from "react-icons/fa";
import Form from "./Form";
import withControl from "../../utils/withControl";
import Modal from "./Modal";

const Message = styled.span`
  ${Typography.label}
  align-self: center;
  margin: auto;
  color: black;
  font-weight: 400;
`;

const HelpText = styled.div`
  ${Typography.small}
  font-weight: normal
`;

const PhotosContainer = styled(Row)`
  overflow-x: auto;
  height: 220px;
  align-items: flex-end;
  padding-top: 12px;
  padding-bottom: 12px;
  flex-wrap: nowrap;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
`;

const PhotoContainer = styled.div`
  background: #ffc8c8;
  border-radius: 20px;
  width: 319px;
  height: 204px;
  margin-right: 24px;
  flex-shrink: 0;
  position: relative;
`;

const DeletePhotoButton = styled(IconButton)`
  position: absolute;
  top: -15px;
  right: -15px;
`;

const Photo = ({ photo, editable, deletePhoto }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  const handleDelete = () => {
    deletePhoto();
    setShowDeleteConfirmation(false);
  };

  return (
    <PhotoContainer>
      <Image src={photo} />
      <Modal
        title="Delete photo?"
        visible={showDeleteConfirmation}
        setVisible={setShowDeleteConfirmation}
        buttons={[
          {
            children: "cancel",
            color: Colors.gray,
            onClick: () => setShowDeleteConfirmation(false),
          },
          {
            children: "delete",
            onClick: handleDelete,
          },
        ]}
      >
        Are you sure you want to delete this photo?
      </Modal>
      {editable && (
        <DeletePhotoButton
          icon={FaTimes}
          onClick={() => setShowDeleteConfirmation(true)}
        />
      )}
    </PhotoContainer>
  );
};

const UploadPhotoButton = styled.div`
  ${Typography.regular}
  font-weight: 500;
  line-height: 21px;
  text-decoration-line: underline;
  cursor: pointer;
`;

/*
 * Props:
 *      - value [string[]] - array of photo source uri to display
 *      - onChange [function] - function to run on value change
 *      - editable [boolean] - if photos can be edited (delete photos + upload new ones)
 */

const Photos = ({ value, onChange, editable }) => {
  const [imageUrls, setImageUrls] = React.useState(value);
  const fileRef = React.createRef();

  React.useEffect(() => {
    if (imageUrls.length === 0) setImageUrls(value);
  }, [value, imageUrls]);

  const deletePhoto = (index) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
    const newImageUrls = [...imageUrls];
    newImageUrls.splice(index, 1);
    setImageUrls(newImageUrls);
  };

  const onFileChange = async (event) => {
    const { target } = event;
    const promises = Array.from(target.files).map(
      async (file) =>
        await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve({ file, base64: reader.result }); //! MOCK photo uploading. TODO: replace with actual flow
          };
          reader.onerror = reject;
        })
    );
    const items = await Promise.all(promises);
    const newImageUrls = [...imageUrls, ...items.map((item) => item.base64)];
    setImageUrls(newImageUrls);
    const newValue = [...value, ...items.map((item) => item.file)];
    onChange(newValue);
    target.value = "";
  };

  return (
    <>
      <input
        type="file"
        onChange={onFileChange}
        ref={fileRef}
        accept="image/*"
        style={{ display: "none" }}
        multiple
      />
      <Row justify="space-between" align="flex-end">
        <Form.Label style={{ margin: 0 }}>Photos</Form.Label>
        {editable && (
          <Row align="center" gap={20}>
            <HelpText>Max 5MB per photo</HelpText>
            <UploadPhotoButton onClick={() => fileRef.current.click()}>
              Upload...
            </UploadPhotoButton>
          </Row>
        )}
      </Row>
      <PhotosContainer>
        {!value || value.length === 0 ? (
          <Message>No photos yet.</Message>
        ) : (
          imageUrls.map((photo, index) => (
            <Photo
              photo={photo}
              editable={editable}
              key={index}
              deletePhoto={() => deletePhoto(index)}
            />
          ))
        )}
      </PhotosContainer>
    </>
  );
};

export const ControlledPhotos = withControl(Photos);

export default Photos;
