import React from "react";
import BoxContainer from "../components/atoms/BoxContainer";
import Column from "../components/atoms/Column";
import { ControlledInput } from "../components/atoms/Input";
import Button from "../components/atoms/Button";
import Form from "../components/molecules/Form";
import { useForm } from "react-hook-form";
import { useLocation } from "@reach/router";
import CognitoClient from "../utils/CognitoClient";
import { ToastContext } from "providers/ToastProvider";
import { FORM_LIMITS, PASSWORD_REQS } from "utils/constants";
import styled from "styled-components";
import Logo from "components/molecules/Logo";
import Row from "components/atoms/Row";
import Space from "components/atoms/Space";
import Link from "components/atoms/Link";

const Container = styled(Column)`
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
`;

const ResetPassword = ({ navigate }) => {
  const { showToast } = React.useContext(ToastContext);
  const { control, handleSubmit, watch } = useForm();
  const { state } = useLocation();
  const passedEmail = state && state.email ? state.email : "";
  const [disabled, setDisabled] = React.useState(false);
  const [step, setStep] = React.useState("send");

  const sendCode = async (data) => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    try {
      await CognitoClient.sendPasswordResetEmail(data);
      showToast({ message: "Password reset code sent.", type: "success" });
      setStep("submit");
    } catch (error) {
      showToast({
        message: `Something went wrong: ${error.message}`,
        type: "error",
      });
    }
    setDisabled(false);
  };

  const submitNew = async (data) => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    try {
      await CognitoClient.submitPasswordReset(data);
      showToast({
        message: "Password was successfully reset.",
        type: "success",
      });
      setDisabled(false);
      navigate("/");
    } catch (error) {
      showToast({
        message: `Something went wrong: ${error.message}`,
        type: "error",
      });
      setDisabled(false);
    }
  };

  const onError = (errors) => {
    console.log(
      Object.entries(errors).map(
        (pair) => pair[0] + " failed the test " + pair[1].type
      )
    );
    showToast({
      message: "Please fix all errors in form fields to continue.",
      type: "error",
    });
  };

  return (
    <Container>
      <BoxContainer>
        <Form.Container>
          <Form.Content>
            <Row justify="center">
              <Logo width="300px" />
            </Row>
            <Form.Heading>Reset password</Form.Heading>
            <Row justify="center">
              {step !== "send" && (
                <Form.GrayLabel>
                  Check your inbox/spam folder. We sent you a code.
                </Form.GrayLabel>
              )}
            </Row>
            <Space height={10} />
            <Form.InputGroup>
              <Form.Label>Email</Form.Label>
              <ControlledInput
                name="email"
                control={control}
                disabled={step === "submit"}
                defaultValue={passedEmail}
                width="100%"
                rules={{
                  required: true,
                  maxLength: FORM_LIMITS.email,
                  validate: { isEmail: CognitoClient.isEmailValid },
                }}
              />
            </Form.InputGroup>
            {step === "send" ? null : (
              <>
                <Form.InputGroup>
                  <Form.Label>Code</Form.Label>
                  <ControlledInput
                    name="code"
                    control={control}
                    width="100%"
                    maxChars={6}
                    rules={{
                      required: true,
                      minLength: 6,
                      pattern: /^\d+$/,
                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup>
                  <Form.Label>New password</Form.Label>
                  <ControlledInput
                    name="newPassword"
                    type="password"
                    control={control}
                    helpText={PASSWORD_REQS}
                    width="100%"
                    rules={{
                      required: true,
                      validate: {
                        passwordStength: CognitoClient.isPasswordValid,
                      },
                    }}
                  />
                </Form.InputGroup>
                <Form.InputGroup>
                  <Form.Label>Confirm new password</Form.Label>
                  <ControlledInput
                    name="confirmPassword"
                    type="password"
                    control={control}
                    width="100%"
                    rules={{
                      required: true,
                      validate: {
                        passwordStength: CognitoClient.isPasswordValid,
                        passwordsMatch: (v) => {
                          return watch("newPassword") === v;
                        },
                      },
                    }}
                  />
                </Form.InputGroup>
              </>
            )}
            <Form.Actions>
              <Column align="center" flex="1">
                {step === "send" ? (
                  <Button
                    disabled={disabled}
                    onClick={handleSubmit(sendCode, onError)}
                  >
                    send verification code
                  </Button>
                ) : (
                  <>
                    <Button
                      disabled={disabled}
                      onClick={handleSubmit(submitNew, onError)}
                    >
                      reset password
                    </Button>
                    <Button
                      disabled={disabled}
                      onClick={() => sendCode({ email: watch("email") })}
                    >
                      resend code
                    </Button>
                  </>
                )}
                <Space height={10} />
                <Link to="/signin">back to sign in</Link>
              </Column>
            </Form.Actions>
          </Form.Content>
        </Form.Container>
      </BoxContainer>
    </Container>
  );
};

export default ResetPassword;
